

.quantity {
  float: left;
  margin-right: 15px;
  background-color: #eee;
  position: relative;
  width: 80px;
  overflow: hidden
}

.quantity input {
  margin: 0;
  text-align: center;
  width: 15px;
  height: 15px;
  padding: 0;
  float: right;
  color: #000;
  font-size: 20px;
  border: 0;
  outline: 0;
  background-color: #F6F6F6
}

.quantity input.qty {
  position: relative;
  border: 0;
  width: 100%;
  height: 40px;
  padding: 10px 25px 10px 10px;
  text-align: center;
  font-weight: 400;
  font-size: 15px;
  border-radius: 0;
  background-clip: padding-box
}

.quantity .minus, .quantity .plus {
  line-height: 0;
  background-clip: padding-box;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-background-size: 6px 30px;
  -moz-background-size: 6px 30px;
  color: #bbb;
  font-size: 20px;
  position: absolute;
  height: 50%;
  border: 0;
  right: 0;
  padding: 0;
  width: 25px;
  z-index: 3
}

.quantity .minus:hover, .quantity .plus:hover {
  background-color: #dad8da
}

.quantity .minus {
  bottom: 0
}
.shopping-cart {
  margin-top: 20px;
}

@media only screen and (max-width: 768px) {
  .product-name {
    font-size: 1rem;
    margin-top: .5rem;
  }

  .product-description {
    font-size: 1rem;
  }

  .product-quantity-container {
    margin-top: .5rem;
  }
}




.quantitynew {
  display: flex;
  flex-direction:row;
  margin-right: 15px;
  background-color: #F6F6F6;
  overflow: hidden
}

.quantitynew input {
  margin: 0;
  text-align: center;
  width: 15px;
  height: 15px;
  padding: 0;
  color: #000;
  font-size: 20px;
  border: 0;
  outline: 0;
  background-color: #F6F6F6
}

.quantitynew input.qty {
  background-color: #F6F6F6;
  position: relative;
  border: 0;
  width: 100%;
  height: 40px;
  padding: 10px 0px 10px 10px;
  text-align: center;
  font-weight: 400;
  font-size: 15px;
  border-radius: 0;
  background-clip: padding-box
}

.quantitynew .minus, .quantitynew .plus {

  font-size: 20px;
  border-radius: 50%;
  background-color: #dad8da;
  border: 0px solid #dad8da;

  height: 100%;
  border: 1px;
  right: 0;
  padding: 10px;
  width: 100%;

}

.quantity .minus:hover, .quantity .plus:hover {
  background-color: #dad8da
}

.quantity .minus {
  bottom: 0
}
.shopping-cart {
  margin-top: 20px;
}

@media only screen and (max-width: 768px) {
  .product-name {
    font-size: 50;
    margin-top: .5rem;
  }

  .product-description {
    font-size: 30;
  }

  .product-quantity-container {
    margin-top: .5rem;
  }
}
