.gallery-wrap .img-small-wrap .item-gallery {
  width: 76px;
  height: 50px;
  border: 1px solid #ddd;
  margin: 7px 2px;
  display: inline-block;
  overflow: hidden;
}

.gallery-wrap .img-small-wrap {
  text-align: center;
}
.gallery-wrap .img-small-wrap img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 4px;
  cursor: pointer;
}

.left-arrow {
  margin: 20px;
  position: absolute;
  left: 0;
  bottom: 45%;
  background-color: #f3f3f6;
}

.right-arrow {
  margin: 20px;
  position: absolute;
  right: 0;
  bottom: 45%;
  background-color: #f3f3f6;
}